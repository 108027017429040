<template>
  <div class="van-main has-fixed-btn">
    <van-tabs v-model="active">
      <van-tab title="实际收款">
        <collectionResults :detail-tables="appDownList"/>
      </van-tab>
      <van-tab title="结算单">
        <div class="van-common-detail">
          <paymentStatement :detail-tables="settleList" type="payment"/>
        </div>
      </van-tab>
      <van-tab title="相关附件">
        <attachment-list module-name="PAYMENT" :business-id="$route.query.collectionId" />
      </van-tab>
      <van-tab title="审核意见">
        <workflow-audit :task-id="$route.query.taskId" page-key="charge_apply" :business-key="$route.query.orderId" @confirmAudit="utils.backReturn($route.query.messageType)" />
      </van-tab>
    </van-tabs>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, uploader } from 'vant'
import paymentStatement from '@/components/payment/payment-statement'
import collectionResults from '@/components/collection/collection-results'
import AttachmentList from '@/components/attachment-list'
import WorkflowAudit from '@/components/workflow-audit'
import BackButton from '@/components/back-button'
// import fileList from '@/components/file-list'
export default {
  components: {
    BackButton,
    paymentStatement,
    collectionResults,
    AttachmentList,
    WorkflowAudit,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [uploader.name]: uploader
    // fileList
  },
  data () {
    return {
      id: this.$route.query.id,
      active: 0,
      contractForm: {
        signInfoList: [],
        fileDetailInfoVoApply: null,
        fileDetailInfoVoSeal: null
      },
      fileList: [],
      appDownList: [],
      settleList: {
        name: '1111'
      }
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>

</style>
